import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import NavBar from "../../Navbar/NavBar";
import Header from "../../Common/Header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { getAPI, postAPI } from "../../utils/api";
import Spinner from "../../Common/Spinner";
import { trimString } from "../../utils/helper";
import moment from "moment";
import Modal from "react-modal";
import { MediaBaseUrl } from "../../utils/BaseUrl";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgb(0,0,0,0.7)",
    zIndex: 999999,
  },
};

const VideoUploads = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [videos, setVideos] = useState<any[]>();
  const [showMedia, setShowMedia] = useState({ open: false, type: "" });
  const [mediaPreview, setMediaPreview] = useState<any>("");
  const[oncurser,setoncurser]=useState<boolean>(true)
  const getData = () => {
    getAPI("/admin/getAllVideos").then((res) => {
      if (res?.data?.status) {
        setVideos(res?.data?.data);
        toast.success(res?.data?.message, { autoClose: 2000 });
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    setLoader(true);
    getData();
  }, []);

  const handleIconClick = (type: string, video: any) => {
    setMediaPreview(video);
    if (type === "cover") {
      setShowMedia({ open: true, type });
    } else if (type === "video") {
      setShowMedia({ open: true, type: "video" });
    } else if (type === "snippet") {
      setShowMedia({ open: true, type: "snippet" });
    }
  };

  const modalClose = () => {
    setShowMedia({ open: false, type: "" });
    setMediaPreview({});
  };

  const handlePublish = (videoId: string, status: boolean) => {
    postAPI("/admin/video/publish", { videoId, status }).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        getData();
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    });
  };

  const deleteVideo = (videoId: string) => {
    postAPI("/admin/video/delete", { videoId }).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message, { autoClose: 2000 });
        getData();
      } else {
        toast.error(res?.data?.message, { autoClose: 2000 });
      }
    });
  };

  return (
    <div>
      <NavBar />
      <div className="main-content">
        <Header text={"Video Uploads"} />
        <div className="container-fluid  pb-5">
          {/* Video Detail */}
          <div
            className="border border-dark rounded border-top-0"
            style={{ overflow: "auto" }}
          >
            <table className="rounded table mb-0">
              <thead>
                <tr>
                  <th className="bg-dark text-white p-3">#</th>
                  <th className="bg-dark text-white p-3">Title</th>
                  <th className="bg-dark text-white p-3">Description</th>
                  <th className="bg-dark text-white p-3">Artist</th>
                  <th className="bg-dark text-white p-3">Type</th>
                  <th className="bg-dark text-white p-3">Genre</th>
                  <th className="bg-dark text-white p-3">video</th>
                  <th className="bg-dark text-white p-3">Cover</th>
                  <th className="bg-dark text-white p-3">Snippet</th>
                  <th className="bg-dark text-white p-3">License</th>
                  <th className="bg-dark text-white p-3">Nudity</th>
                  <th className="bg-dark text-white p-3">Product Date</th>
                  <th className="bg-dark text-white p-3">Status</th>
                  <th  className="bg-dark text-white p-3"></th>
                  {/* <th className="bg-dark text-white"></th> */}
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <Spinner />
                ) : (
                  videos?.map((video, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{video?.videoTitle}</td>
                      <td data-toggle="tooltip" data-placement="bottom" title={video.description} >
                        {trimString(video?.description, 50)}
                      </td>
                      <td>{video?.artistName}</td>
                      <td>{video?.videoType}</td>
                      <td>{video?.genre}</td>
                      <td className="cursorPointer">
                        {
                          video?.videoLink 
                          ? 
                          <i onClick={() => handleIconClick("video", video)} className="h1 bi bi-film" ></i>
                          :
                          "-"
                        }
                      </td>
                      <td className="cursorPointer">
                        {
                          video?.coverPhoto
                          ?
                          <i onClick={() => handleIconClick("cover", video)} className="h1 bi bi-image " ></i>
                          :
                          "-"
                        }
                      </td>
                      <td className="cursorPointer">
                        {
                          video?.snippetLink 
                          ?
                          <i onClick={() => handleIconClick("snippet", video)} className="h1 bi bi-file-earmark-play" />
                          :
                          "-"
                        }
                      </td>
                      <td>{video?.license}</td>
                      <td>{video?.isNudity ? "Yes" : "No"}</td>
                      <td>
                        {moment(video?.dateOfProduct).format("MM/DD/YYYY")}
                      </td>
                      <td>
                        {
                          video?.status === "pending" && <span className="badge badge-secondary cursorPointer" > Pending </span>
                        }
                        {
                          video?.status === "approved" && <span className="badge badge-success cursorPointer" > Approved </span>
                        }
                        {
                          video?.status === "rejected" && <span className="badge badge-danger cursorPointer" > Rejected </span>
                        }
                      </td>
                      <td className="text-right">
                        <div className="dropdown">
                          <a href="#/" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                            <i className="fe fe-more-vertical" style={{ color: "#12263F" }} />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">

                            <button 
                              onClick={() => handlePublish(video.uid, true)} 
                              className="dropdown-item" 
                              disabled={video?.status==="approved"?true:false}
                            > Approve </button>

							              <button 
                              onClick={() => handlePublish(video.uid, false)} 
                              className="dropdown-item" 
                              disabled={video?.status==="rejected"?true:false}
                            > Reject </button>

                            <button 
                              className="dropdown-item" 
                              disabled={video?.status === "rejected" ? false : true} 
                              onClick={() => deleteVideo(video.uid)}
                            > Delete </button>
                          </div>
                         
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showMedia?.type === "cover" && (
        <Modal
          isOpen={showMedia?.open}
          style={customStyles}
          onRequestClose={modalClose}
          contentLabel="Media Preview"
        >
          <h1 className="d-flex align-items-center justify-content-center">
            Media Preview
          </h1>
          <h3 className="d-flex align-items-center justify-content-center">
            {mediaPreview?.videoTitle}
          </h3>
          <div className="container d-flex align-items-center justify-content-center " >
            <img
              className="modalMediaPreview" width="80%" height="600"
              src={MediaBaseUrl + "/" + mediaPreview?.coverPhoto}
			  alt="..."
            />
          </div>
        </Modal>
      )}
      {showMedia.type === "video" && (
        <Modal
          isOpen={showMedia?.open}
          style={customStyles}
          onRequestClose={modalClose}
          contentLabel="Media Preview"
        >
          <h1 className="d-flex align-items-center justify-content-center">
            Media Preview
          </h1>
          <h3 className="d-flex align-items-center justify-content-center">
            {mediaPreview?.videoTitle}
          </h3>
          <div className="d-flex aligh-items-center justify-content-center">
            <video
              className="modalMediaPreview"
              src={MediaBaseUrl + "/" + mediaPreview?.videoLink}
              controls
            />
          </div>
        </Modal>
      )}
      {showMedia.type === "snippet" && (
        <Modal
          isOpen={showMedia?.open}
          style={customStyles}
          onRequestClose={modalClose}
          contentLabel="Media Preview"
        >
          <h1 className="d-flex align-items-center justify-content-center">
            Media Preview
          </h1>
          <h3 className="d-flex align-items-center justify-content-center">
            {mediaPreview?.videoTitle}
          </h3>
          <div className="d-flex aligh-items-center justify-content-center">
            <video
              className="modalMediaPreview"
              src={MediaBaseUrl + "/" + mediaPreview?.snippetLink}
              controls
            />
          </div>
        </Modal>
      )}
      <ToastContainer />
    </div>
  );
};

export default VideoUploads;
