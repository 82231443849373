import React, { useState } from "react";
import DatePicker from "react-datepicker";
import exportFromJSON from "export-from-json";

import "react-datepicker/dist/react-datepicker.css"; 
import NavBar from "../../Navbar/NavBar";
import Header from "../../Common/Header";
import { getAPI } from "../../utils/api";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { trimDate } from "../../utils/helper";

const Home = () => {
	const [usersDetailDate, setUsersDetailDate] = useState({from: new Date(), to:new Date()})
	const [loading, setLoading] = useState({userDetail: false, userLeaderboard: false})
	const exportType = exportFromJSON.types.csv

	const userDetailSubmit = () => {
		setLoading({...loading, userDetail: true})
		const from = trimDate(usersDetailDate?.from)
		const to = trimDate(usersDetailDate?.to)
		getAPI(`/admin/user/report`)
		.then(res => {
			if(res?.data?.data?.length){
				exportFromJSON({ data: res.data.data, fileName: "User Detail", exportType })
				toast.success('File Downloaded', { position: "top-right", autoClose: 3000 })
			} else {	
				toast.error("No Users found", { position: "top-right", autoClose: 3000 })
			}
			setLoading({...loading, userDetail: false})
		}).catch(err => {
			toast.error("Something went wrong", { position: "top-right", autoClose: 3000 }) 
			setLoading({...loading, userDetail: false})
		})
	}

	const handleUserDetailDate = (date:any, property?:any) => {
		setUsersDetailDate({
			...usersDetailDate,
			[property]: date
		})
	}

	return (<div>
		<NavBar />
		<div className="main-content">
			<Header text={"Reports"} />
			<div className="container-fluid">
				{/* Users Detail */}
				<div className="row mx-1 mb-2">
					<h3>
						User's Detail
						<button onClick={userDetailSubmit} className="mx-5 mr-2 my-2 btn btn-primary" disabled={loading?.userDetail} >
							{loading?.userDetail ? "Please wait ..." : "Generate report"}
						</button>
						</h3>
					</div>
				{/* <div className="row mb-6">
						<div className="col-md-3">
							<span>
								From:
								<DatePicker selected={usersDetailDate?.from} onChange={(date:any) => handleUserDetailDate(date, "from")} disabled={loading?.userDetail} maxDate={usersDetailDate?.to} /> 
							</span>
						</div>
					<div className="col-md-3">
						<span>
							To:
							<DatePicker selected={usersDetailDate?.to} onChange={(date:any) => handleUserDetailDate(date, "to")} disabled={loading?.userDetail} maxDate={new Date()} />
						</span>
					</div>
					<div className="col-md-3 d-flex align-items-end">
						
					</div>
				</div> */}
				<hr />


			</div>
		</div>
		<ToastContainer />
		</div>
	);
};

export default Home;
