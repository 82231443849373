import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Artists from "./Page/Artists/Artists";
import Fans from "./Page/Fans/Fans";
import Login from "./Page/Login/Login";
import Settings from "./Page/Settings/Settings";
import VideoUploads from "./Page/VideoUploads/VideoUploads";
import Reports from "./Page/Reports/Reports"

const App = () => {
  
  const token = localStorage.getItem("token");

  const PrivateRoute = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Fans />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/video-uploads" element={<VideoUploads/>} />
          <Route path="/reports" element={<Reports/>} />
          <Route path="/settings" element={<Settings/>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    );
  };

  const PublicRoute = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Login/>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    );
  };

  return token ? <PrivateRoute /> : <PublicRoute />
  
};

export default App;
