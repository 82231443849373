import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './Assets/css/theme.css'
import './Assets/css/style.css'
import './Assets/fonts/feather/feather.css'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
