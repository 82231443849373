import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Baseurl } from "../../utils/BaseUrl";
import { postPublicAPI } from "../../utils/api";
import Axios from "axios";
const Login = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [response, setResponse] = useState(false);
  const [errors, setErrors] = useState<any>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showPwd, setShowPwd] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const submit = (event: any) => {
    event.preventDefault();
    const { email, password } = user;
    if (email && password) {
      setIsSubmitting(true);
      try {
        Axios.post(`${Baseurl}/admin/login`, user).then((res:any) => {
          if (res.data.status) {
            setResponse(res.data.message);
            localStorage.setItem("token", res.data.token);
            window.location.reload()
            // navigate("/home");
            setIsSubmitting(false);
          } else {
            setErrors(res.data.message);
            setIsSubmitting(false);
          }
        });
      } catch (err) {
        setErrors("Some error occured!!");
      }
    } else {
      setErrors("All fields are required!!");
    }
  };

  return (
    <React.Fragment>
      <div className="main-section">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center ">
            {/* Left Section start */}
            <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">
              {errors ? (
                <div className="alert alert-danger fade show error-message" role="alert" >
                  <div className="text-center">{errors}</div>
                </div>
              ) : (
                ""
              )}

              {response ? (
                <div className="alert alert-success" role="alert">
                  <div className="text-center">{response}</div>
                </div>
              ) : (
                ""
              )}

              <div className="logo-section text-center mb-5">
                <img style={{ width: "30%" }} src={require("../../Assets/img/logo.png")} alt="..." />
              </div>
              <form>
                <div className="form-group">
                  <label>Email Address</label>
                  <input type="email" className="form-control focus-out" placeholder="name@address.com" name="email" value={user.email} onChange={handleChange} disabled={isSubmitting} />
                  <div className="form-group">
                    <div className="row">
                      <div className="col">
                        <label>Password</label>
                      </div>
                    </div>
                    <div className="input-group input-group-merge">
                      <input type={`${showPwd ? `text` : "password"}`} className="form-control focus-out form-control" placeholder="Enter your password" name="password" value={user.password} onChange={handleChange} disabled={isSubmitting} />

                      <div className="input-group-append show-password">
                        <span className="" onClick={() => setShowPwd(!showPwd)}>
                          <i className={`fe ${ showPwd ? `fe-eye-off` : `fe-eye` }`} />
                        </span>
                      </div>
                    </div>
                    {isValid && errors.password.length > 0 ? (
                      <span className="error-field-error">
                        {errors.password}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <button className="btn btn-lg btn-block mb-3 background-orange" type="submit" disabled={isSubmitting} onClick={submit} >
                    {isSubmitting ? (
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </form>
            </div>
            {/* Left Section End */}

            {/* Right Section start */}
            <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
              <div
                className="bg-cover vh-100 mt-n1 mr-n3" 
				style={{ 
					background: `url("https://honey-streaming.com/assets/images/hero-banner.png")`, 
					backgroundRepeat: "no-repeat", 
					backgroundSize: "cover", 
					backgroundPosition: "center"
				}}
              />
            </div>
            {/* Right Section End */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Login;
