import "react-datepicker/dist/react-datepicker.css";
import NavBar from "../../Navbar/NavBar";
import Header from "../../Common/Header";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Settings = () => {
	

	return (<div>
		<NavBar />
		<div className="main-content">
			<Header text={"Settings"} />
			<div className="container-fluid">
				{/* Users Detail */}
				<div className="row mb-2"><h3>Settings</h3></div>
				
			</div>
		</div>
		<ToastContainer />
		</div>
	);
};

export default Settings;
