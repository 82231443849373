import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  return (
    <React.Fragment>
      <nav
        className="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark navbar-vibrant"
        id="sidebar"
      >
        <div className="container-fluid">
          {/* Toggler */}
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          
          <a className="d-flex justify-content-center align-items-center mb-4" href="/">
          {/* <img src={require("../Assets/img/logo.png")} /> */}
            <img  src={require("../Assets/img/logo.png")} className="navbar-brand-img" alt="..." />
          </a>
          <div className="collapse navbar-collapse" id="sidebarCollapse">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/fans" role="button" aria-expanded="false" aria-controls="Dashboards" >
                  <i className="fe fe-users" /> Fans
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/artists" role="button" aria-expanded="false" aria-controls="Dashboards" >
                <i className="fe fe-user-check" /> Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/video-uploads" role="button" aria-expanded="false" aria-controls="Dashboards" >
                  <i className="fe fe-video" /> Video Uploads
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/reports" role="button" aria-expanded="false" aria-controls="Dashboards" >
                  <i className="fe fe-file-text" /> Reports
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/settings" role="button" aria-expanded="false" aria-controls="Dashboards" >
                  <i className="fe fe-settings" /> Settings
                </a>
              </li>
              <li className="nav-item cursorPointer">
                <span id="ii" className="nav-link" data-target="#logout" data-toggle="modal" >
                  <i className="fe fe-log-out" /> Logout
                </span>
              </li>
            </ul>
            <div className="mt-auto" />
          </div>
          <div className="navbar-user d-md-block d-none" id="sidebarUser">
            <a href="#" className="d-block text-center pb-2">
              <img width="30%" src={require("../Assets/img/logo.png")} alt="..." />
            </a>
            <p className="d-block text-white text-center mb-0 opacity-8 font-sm">
              Copyright © 2022 Honey Streaming
              <br />
              All Rights Reserved.
            </p>
          </div>
        </div>
      </nav>
      <div
        className="modal"
        id="logout"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card-header">
              <h4
                className="card-header-title text-center"
                id="exampleModalCenterTitle"
              >
                Do you really want to Logout?
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12 text-center">
                <button
                  type="button"
                  className="btn btn-secondary btn-lg mr-3"
                  data-dismiss="modal"
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  data-dismiss="modal"
                  onClick={handleLogout}
                >
                  {" "}
                  Logout{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NavBar;
