import React from 'react';

const Header = (props:{text:string}) => {
    return (
        <div className="col-12 col-xl-12">
            <div className="card text-right p-3">
                <h1 className='text-left'>{props?.text || "Admin - Honey Streaming"}</h1>
            </div>
        </div>
    )
}

export default Header