import "react-datepicker/dist/react-datepicker.css";
import NavBar from "../../Navbar/NavBar";
import Header from "../../Common/Header";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { getAPI, postAPI} from "../../utils/api";
import Spinner from "../../Common/Spinner";
import { MediaBaseUrl } from "../../utils/BaseUrl";
import { IArtist } from "../../utils/interface";
import moment from "moment";

const Artists = () => {
	
	const [ loader, setLoader ] = useState<boolean>(false)
	const [ artists, setArtists ] = useState<any[]>()

	const getData = () => {
		getAPI("/admin/getAllUsers?userType=artist").then(res => {
			if(res?.data?.status){
				setArtists(res?.data?.data)
				toast.success(res?.data?.message,{autoClose: 2000})
			} else {
				toast.error(res?.data?.message,{autoClose: 2000})
			}
			setLoader(false)
		})
	}
	useEffect(() => {
		setLoader(true)
		getData()
	}, [])

	const handleSuspend = (userId: string, status: boolean) => {
		postAPI("/admin/user/suspend", {userId, status}).then((res) => {
			if (res?.data?.status) {
			  toast.success(res?.data?.message, { autoClose: 2000 });
			  getData()
			} else {
			  toast.error(res?.data?.message, { autoClose: 2000 });
			}
		})
		;
	}


	return (<div>
		<NavBar />
		<div className="main-content">
			<Header text={"Artists Details"} />
			<div className="container-fluid pb-5">
				{/* Artist Detail */}
				<div className="border  rounded border-top-0" style={{ overflow: "auto" }}>
					<table className="rounded table mb-0">
						<thead>
							<tr >
								<th className="bg-dark text-white">#</th>
								<th className="bg-dark text-white min-w-190">Full Name</th>
								<th className="bg-dark text-white">Artist Name</th>
								<th className="bg-dark text-white">Email</th>
								<th className="bg-dark text-white">D.O.B.</th>
								<th className="bg-dark text-white maxWidth200">Full Address</th>
								<th className="bg-dark text-white">Phone</th>
								<th className="bg-dark text-white"></th>								
								<th className="bg-dark text-white"></th>
							</tr>
						</thead>
						<tbody>
							{
								loader
								?
								<Spinner />
								: 
								(
									artists?.length
									?
									artists.map((artist: IArtist, index:number) => {
										return <tr key={index}>
											<td className="border-bottom">{index+1}</td>
											<td className="border-bottom">
												<span className="user_profile border_rounded">
													<img src={artist.profilePic ? MediaBaseUrl+artist.profilePic : MediaBaseUrl+"/assets/images/follow/user.png"} alt={artist.firstName+" "+artist.lastName} className="user_profile mx-1 img-fluid border-rounded rounded-circle" />
												</span>
												{(artist.firstName?artist.firstName:"") + " " + (artist.lastName?artist.lastName:"")}
											</td>
											<td className="border-bottom">{artist.artistName?artist.artistName:"-"}</td>
											<td className="border-bottom">{artist.email?artist.email:"-"}</td>
											<td className="border-bottom">{artist.dateOfBirth ? moment(artist.dateOfBirth).format('MM/DD/YYYY') : "-"}</td>
											<td className="border-bottom maxWidth200">
												{artist.address ? artist.address+", " : ""}
												{artist.city ? artist.city+", " : ""}
												{artist.state ? artist.state+", " : ""}
												{artist.country ? artist.country+", " : ""}
											</td>
											<td className="border-bottom">{artist.phone ? artist.phone : "-"}</td>
                                            <td className="border-bottom">
												{
													artist?.isActive
													?
													<span className="badge badge-success cursorPointer">Active</span>
													:
													<span className="badge badge-secondary cursorPointer">Suspended</span>
									            }
											</td>
											<td>
												<div className="dropdown">
													<a href="#/" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                    <i className="fe fe-more-vertical" style={{ color: "#12263F" }} />
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
														<button onClick={() => handleSuspend(artist.uid, true)} className="dropdown-item" disabled={artist.isActive ? true : false}> Active </button>
														<button onClick={() => handleSuspend(artist.uid, false)} className="dropdown-item" disabled={artist.isActive ? false : true}> Suspend </button>
                                                    </div>
                                                </div>
											</td>
										</tr>
									})
									:
									<tr className="">No Data Found</tr>
								)
							}
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<ToastContainer />
	</div>
	);
};

export default Artists;
